import { LitElement, html, css, unsafeCSS } from 'lit';

import { Modal } from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';

export class ExcelTrackingConsole extends LitElement {

    render() {
        return html`
        <div>
            <h1>Excel Tracker Planning</h1>
            <div class="row mb-4">
                <label for="planDate" class="col-2 col-form-label">Plan Date</label>
                <div class="col-2">
                    <input id="planDate" ?disabled=${this.solving || this.uploading} @input=${this.planDateChanged} type="text" class="form-control" .value=${this.planDate}></input>
                </div>
                <label for="tcPsddFromDate" class="col-2 col-form-label">TCPSDD From Date</label>
                <div class="col-2">
                    <input id="tcPsddFrom" ?disabled=${this.solving || this.uploading} @input=${this.tcPsddFromChanged} type="text" class="form-control" .value=${this.tcPsddFrom}></input>
                </div>
                <label for="tcPsddToDate" class="col-2 col-form-label">TCPSDD To Date</label>
                <div class="col-2">
                    <input id="tcPsddTo" ?disabled=${this.solving || this.uploading} @input=${this.tcPsddToChanged} type="text" class="form-control" .value=${this.tcPsddTo}></input>
                </div>
            </div>
            <div class="row mb-3">
                <label for="fileField" class="col-2 col-form-label">Select file to upload:</label>
                <div class="col-10">
                    <input id="fileField" ?disabled=${this.solving || this.uploading} @input=${this.fileChanged} type="file" class="form-control"></input>
                </div>
            </div>
            <button ?disabled=${this.solving || this.uploading || !this.file} @click=${this.upload} class="btn btn-primary">Upload</button>
        </div>
        <div class="modal" id="solverDialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title">Problem ${this.problemId ?? "Uploading..."}</h6>
                    </div>
                    <div class="modal-body">
                        <div ?hidden=${!this.hasSolution}>
                            <a href="/excel-tracking/${this.problemId}/excel/${this.fileName}">Download Excel Result</a>
                        </div>
                        <table ?hidden=${!this.score} class="table">
                            <tr>
                                <th colspan="2">Scores: ${this.score?.feasible ? "Feasible" : "Infeasible"} <button @click=${this.showAnalyze}>?</button></th>
                            </tr> 
                            <tr>
                                <td>Hard</td><td>${JSON.stringify(this.score?.hardScores)}</td>
                            </tr>
                            <tr>
                                <td>Soft</td><td>${JSON.stringify(this.score?.softScores)}</td>
                            </tr>
                            <tr>
                                <td>Start Solving at</td><td>${moment(this.startSolvingTime).format('YYYY-MM-DD hh:mm:ss')}</td>
                            </tr>
                            <tr>
                                <td>Solved for</td>
                                <td>
                                    ${this.startSolvingTime != null ? moment.duration(moment(this.currentTime).diff(this.startSolvingTime)) : "N/A"}
                                </td>
                            </tr>
                            <tr ?hidden=${this.solving}>
                                <td>Stop Solving at</td><td>${moment(this.stopSolvingTime).format('YYYY-MM-DD hh:mm:ss')}</td>
                            </tr>
                            <tr ?hidden=${!this.solving}>
                                <td>
                                    Solve Duration
                                </td>
                                <td>
                                    <input @input=${this.durationToStopChanged} .value=${this.durationToStop ?? ""} placeholder="Minutes More">
                                    <button type="button" @click=${this.setDurationToStop} class="btn btn-sm btn-info">SET</button>
                                </td>
                            </tr>
                            <tr ?hidden=${!this.solving || this.scheduledStopTime == null}>
                                <td>
                                    Scheduled Stopping
                                </td>
                                <td>
                                    ${moment(this.scheduledStopTime).format('YYYY-MM-DD hh:mm:ss')}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button ?hidden=${!this.solving} @click=${this.manualSort} type="button" class="btn btn-info">Sort</button>
                        <button ?hidden=${!this.solving} @click=${this.stopSolving} type="button" class="btn btn-secondary">Stop Solving</button>
                        <button ?hidden=${this.solving || !this.problemId} @click=${this.continueSolving} class="btn btn-light">Continue Solving</button>
                        <button ?hidden=${this.solving || !this.problemId} @click=${this.cleanup} class="btn btn-danger" data-bs-dismiss="modal">Cleanup</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal modal-lg" id="analyzeDialog">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title">Score Analysis ${JSON.stringify(this.score?.hardScores)}/${JSON.stringify(this.score?.softScores)}</h6>
                    </div>
                    <div class="modal-body">
                        <table class="table table-sm table-hover">
                            <tr>
                                <th>Constraint</th>
                                <th>Score Weight</th>
                                <th>Matches</th>
                                <th>Details</th>
                            </tr>
                            ${Object.keys(this.analyze?.constraintMap || []).map((key, idx) =>
            html`<tr>
                                            <td>${this.analyze?.constraintMap[key].constraintRef.constraintName}</td>
                                            <td>${JSON.stringify(this.analyze?.constraintMap[key].weight.hardScores)}/${JSON.stringify(this.analyze?.constraintMap[key].weight.softScores)}</td>
                                            <td>${this.analyze?.constraintMap[key].matches.length}</td>
                                            <td><a ?hidden=${this.analyze?.constraintMap[key].matches.length == 0} data-bs-toggle="collapse" href="#row${idx}Collapse">Show</a></td>
                                        </tr>
                                        <tr class="collapse" id="row${idx}Collapse">
                                            <td colspan="4">
                                            <ul class="list-group">
                                                ${this.analyze?.constraintMap[key].matches.map((match) =>
                html`
                                                        <li class="list-group-item">${match.justification.description}</li>
                                                    `
            )}
                                            </ul>
                                            </td>
                                        </tr>
                                    </tr>`
        )}
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button data-bs-dismiss="modal" type="button" class="btn">Close</button>
                    </div>
                </div>
            </div>
        </div>
          `;
    }

    static properties = {
        problemId: undefined,
        file: undefined,
        fileName: undefined,
        score: undefined,
        analyze: undefined,
        solving: false,
        hasSolution: false,
        uploading: false,
        planDate: { type: String, reflect: true },
        tcPsddFrom: { type: String, reflect: true },
        tcPsddTo: { type: String, reflect: true },
        startSolvingTime: { type: Date },
        currentTime: { type: Date },
        stopSolvingTime: { type: Date },
        durationToStop: { type: Number },
        scheduledStopTime: { type: Date },
    };

    get solverDialog() {
        return this.renderRoot?.querySelector('#solverDialog') ?? null;
    }

    get analyzeDialog() {
        return this.renderRoot?.querySelector('#analyzeDialog') ?? null;
    }

    constructor() {
        super();
        this.problemId = undefined;
        this.file = undefined;
        this.fileName = undefined;
        this.score = undefined;
        this.analyze = undefined;
        this.solving = false;
        this.hasSolution = false;
        this.planDate = moment().format("YYYY-MM-DD");
        this.tcPsddFrom = moment().format("YYYY-MM-DD");
        this.tcPsddTo = moment().add(2, 'months').format("YYYY-MM-DD");
        this.nextDays = 20;
        this.startSolvingTime = null;
        this.stopSolvingTime = null;
        this.currentTime = null;
        this.scheduledStopTime = null;
    }

    fileChanged(event) {
        var input = event.target;
        this.file = input.files[0];
    }

    planDateChanged(event) {
        this.planDate = event.target.value;
    }

    tcPsddFromChanged(event) {
        this.tcPsddFrom = event.target.value;
    }

    tcPsddToChanged(event) {
        this.tcPsddTo = event.target.value;
    }

    nextDaysChanged(event) {
        this.nextDays = event.target.value;
    }

    durationToStopChanged(event) {
        this.durationToStop = event.target.value;
    }

    async upload(event) {
        this.uploading = true;
        this.fileName = this.file.name;
        if (this.problemId) {
            this.cleanup(this.problemId);
        }
        new Modal(this.solverDialog, { backdrop: "static" }).show();
        this.sendThruNetwork(this.file.type, await this.file.arrayBuffer());
    }

    async cleanup() {
        const pid = this.problemId;
        this.problemId = null;
        this.hasSolution = false;
        this.score = undefined;
        const fetchResponse = await fetch(`/excel-tracking/${pid}/cleanup`, {
            method: 'DELETE'
        });
        const data = await fetchResponse.json();
    }

    async stopSolving() {
        const fetchResponse = await fetch(`/excel-tracking/${this.problemId}`, {
            method: 'DELETE'
        });
        const data = await fetchResponse.json();
        this.score = data.score;
        // delete should have a solution anyways
        this.solving = false;
        this.hasSolution = true;
        this.stopSolvingTime = new Date();
    }

    async continueSolving() {
        const fetchResponse = await fetch(`/excel-tracking/${this.problemId}`, {
            method: 'PUT'
        });
        const data = await fetchResponse.json();
        this.score = data.score;
        this.solving = true;
        this.scheduledStopTime = null;
        setTimeout(this.checkSolver.bind(this), 1000);
    }

    async manualSort() {
        const fetchResponse = await fetch(`/excel-tracking/${this.problemId}/sort`, {
            method: 'PUT'
        });
        const data = await fetchResponse.json();
        this.score = data.score;
    }

    async showAnalyze() {
        const fetchResponse = await fetch(`/excel-tracking/${this.problemId}/analyze`, {
            method: 'GET'
        });
        const data = await fetchResponse.json();
        this.analyze = data;
        new Modal(this.analyzeDialog, { backdrop: "static" }).show();
    }

    async sendThruNetwork(type, arraybuffer) {
        const fetchResponse = await fetch(`/excel-tracking/solve?planDate=${moment(this.planDate).format('YYYY-MM-DD')}&tcPsddFrom=${moment(this.tcPsddFrom).format('YYYY-MM-DD')}&tcPsddTo=${moment(this.tcPsddTo).format('YYYY-MM-DD')}`, {
            method: 'POST',
            headers: { 'Content-Type': type },
            body: arraybuffer
        });
        const data = await fetchResponse.json();
        if (data.success) {
            this.problemId = data.id;
            this.solving = true;
            this.hasSolution = false;
            this.startSolvingTime = new Date();
            this.currentTime = new Date();
            setTimeout(this.checkSolver.bind(this), 1000);
        }
        this.uploading = false;
        return data;
    }

    async checkSolver() {
        const fetchResponse = await fetch(`/excel-tracking/${this.problemId}/score`);
        const data = await fetchResponse.json();
        // console.log(data);
        this.score = data.score;
        this.solving = data.status == 'SOLVING_ACTIVE';
        this.currentTime = new Date();
        if (this.solving) {
            this.solving = true;
            this.hasSolution = this.score.feasible;
            if (this.scheduledStopTime != null && this.currentTime > this.scheduledStopTime) {
                this.stopSolving();
            } else {
                setTimeout(this.checkSolver.bind(this), 1000);
            }
        } else {
            this.solving = false;
            this.hasSolution = true;
            this.stopSolvingTime = new Date();
            this.scheduledStopTime = null;
        }
    }

    setDurationToStop() {
        if (this.durationToStop && this.durationToStop.trim() != "") {
            this.scheduledStopTime = moment().add(this.durationToStop.trim(), "minutes").toDate();
        } else {
            this.scheduledStopTime = null;
        }
    }

    createRenderRoot() {
        return this;
    }
}
customElements.define('excel-tracking-console', ExcelTrackingConsole);